import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  Category,
  CategoryCollection,
} from "@planetadeleste/vue-mc-shopaholic";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Category, CategoryCollection>
>;

@Component
export default class CategoriesMixin extends Mixins(TypedModelMixin) {
  obCollection = new CategoryCollection();
  obModelClass = Category;
  obCollectionClass = CategoryCollection;
  sRouteName = "categories.list";

  created() {
    this.onCreated();
  }
}
